<template>
  <el-dialog
    v-model="showModulesDialog"
    :title="`${props.dialogType==='add'?'添加':'编辑'}模块`"
    top="3vh"
  >
    <el-form
      :model="dialogForm"
      ref="dialogFormRef"
      :rules="rules"
      label-width="80px"
      :scroll-to-error="true"
    >
      <el-form-item label="名称：" prop="name">
        <el-input v-model="dialogForm.name" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="code：" prop="code">
        <el-input v-model="dialogForm.code" placeholder="请输入模块code" />
      </el-form-item>
      <el-form-item label="封面图：" prop="coverUrl">
        <upload-img
          :img-url="dialogForm.coverUrl ? dialogForm.coverUrl : ''"
          @change-avatar="changeCoverPicture"
        />
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input-number :controls="false" v-model="dialogForm.sort" placeholder="请输入排序值，越小越靠前" />
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input type="textarea" v-model="dialogForm.remark" placeholder="请输入备注" />
      </el-form-item>
    </el-form>

    <template #footer>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm" style="margin-right: 5px">确定</el-button>
        <el-button @click="resetForm">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ChangeModule'
}
</script>
<script setup>
import { ref, watch, defineEmits } from 'vue'
import UploadImg from "@/views/home/components/uploadImg";
import { moduleManagement } from '@/api/modules'
import {ElMessage} from "element-plus";

// eslint-disable-next-line no-undef
const props = defineProps({
  dialogType: {
    type: String,
    required: true,
    default: () => 'add'
  },
  dialogData: {
    type: Object,
    required: true,
    default: () => {
    }
  },
  refreshKey: {
    type: Number,
    required: true,
    default: 1
  },
  dialogId: {
    type: Number,
    required: true,
    default: () => 0
  }
})

watch(() => props.refreshKey, () => {
  dialogForm.value = JSON.parse(JSON.stringify(props.dialogData))
})

const showModulesDialog = ref(false)
const dialogForm = ref({
  name: '',
  coverUrl: '',
  remark: '',
  code: '',
  sort: ''
})
const rules = ref({
  name: [{ required: true, message: '此为必填项：请输入名称', trigger: 'blur' }]
})

const changeCoverPicture = (url) => {
  dialogForm.value.coverUrl = url
}

// 发射事件
const emit = defineEmits(['upDateTable'])
const dialogFormRef = ref(null)
const submitForm = () => {
  dialogFormRef.value.validate((valid) => {
    if (valid) {
      const formData = dialogForm.value
      const data = {
        coverUrl: formData.coverUrl,
        code: formData.code,
        name: formData.name,
        remark: formData.remark,
        parentId: props.dialogId,
        sort: Number(formData.sort)
      }
      if (props.dialogType === 'add') {
        moduleManagement.addModule(data).then(() => {
          ElMessage({
            message: '添加成功！',
            type: 'success',
          })
          emit('upDateTable', props.dialogId)
          resetForm()
        })
      } else {
        data.id = formData.id
        moduleManagement.modifyModule(data).then(() => {
          ElMessage({
            message: '编辑成功！',
            type: 'success',
          })
          emit('upDateTable', props.dialogId)
          resetForm()
        })

      }
    } else {
      console.log('未通过')
    }
  })
}

// 关闭弹框
const resetForm = () => {
  showModulesDialog.value = false
}

const editTagDialogType = () => {
  showModulesDialog.value = !showModulesDialog.value
}

defineExpose({
  editTagDialogType
})

</script>

<style>
.el-input__inner {
  text-align: left !important;
}
</style>
