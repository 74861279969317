import axios from '@/api/axios'

export const moduleManagement = {
  // 新增模块
  addModule: (data, loading = true, text = '加载中') => {
    return axios({
      url: '/module/add',
      method: 'post',
      data
    }, { loading }, { text })
  },
  // 编辑模块
  modifyModule: (data, loading = true, text = '加载中') => {
    return axios({
      url: '/module/modify',
      method: 'post',
      data
    }, { loading }, { text })
  },
  // 模块列表
  getModuleList: (data, loading = true, text = '加载中') => {
    return axios({
      url: '/module/list',
      method: 'post',
      data
    }, { loading }, { text })
  },
  // 删除模块
  deleteModule: (data, loading = true, text = '加载中') => {
    return axios({
      url: '/module/delete',
      method: 'post',
      data
    }, { loading }, { text })
  }
}
