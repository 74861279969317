import axios from '@/api/axios'

export const upLoad = {
  imagesUpLoad: (data, loading = true, text = '加载中') => {
    return axios({
      url: '/upload/file',
      method: 'post',
      data
    }, {loading}, {text})
  }
}
