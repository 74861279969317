import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'

const LoadingInstance = {
  _target: null,
  _count: 0
}

function myAxios(axiosConfig, customOptions, loadingOptions) {
  const service = axios.create({
    baseURL: 'https://api.baihui.tucent.net', // 设置统一的请求前缀
    timeout: 1000 * 60 // 设置统一的超时时长
  })

    // 自定义配置
  const custom_options = Object.assign({
    repeat_request_cancel: true, // 是否开启取消重复请求, 默认为 true
    loading: true, // 是否开启loading层效果, 默认为true
    reduce_data_format: true, // 是否开启简洁的数据结构响应, 默认为true
    error_message_show: true, // 是否开启接口错误信息展示,默认为true
    code_message_show: true // 是否开启code不为0时的信息提示, 默认为true
  }, customOptions)

    // 请求拦截
  service.interceptors.request.use(
    config => {
      // 创建loading实例
      if (custom_options.loading) addLoading(loadingOptions)

      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

    // 响应拦截
  service.interceptors.response.use(
    response => {
      custom_options.loading && closeLoading(custom_options) // 关闭loading

      if (custom_options.code_message_show && response.data && response.data.code !== 0 && !(response.data instanceof Blob)) {
        // 30001登录失效
        if (response.data.code === 30001) {
          ElMessage({
            type: 'error',
            message: response.data.message
          })
          return Promise.reject(response.data)
        }
        // 显示错误信息 reject错误
        ElMessage({
          type: 'error',
          message: response.data.message
        })
        return Promise.reject(response.data) // code不等于0, 页面具体逻辑就不执行了
      }

      return custom_options.reduce_data_format ? response.data : response
    },
    error => {
      custom_options.loading && closeLoading(custom_options) // 关闭loading
      return Promise.reject(error) // 错误继续返回给到具体页面
    }
  )

  return service(axiosConfig)
}

/**
 * 初始化Loading层实例
 * @param {*} _options
 */
function addLoading(_options) {
  LoadingInstance._count++
  if (LoadingInstance._count === 1) {
    LoadingInstance._target?.close()
    LoadingInstance._target = ElLoading.service(Object.assign(_options, { lock: true }))
  }
}

/**
 * 关闭Loading层实例
 */
function closeLoading() {
  LoadingInstance._count--
  if (LoadingInstance._count === 0)LoadingInstance._target?.close()
  document.body.classList = []
}

export default myAxios
