<template>
  <div class="home-page">
    <div class="home-page-top">
      <el-button type="primary" @click="getAllModule">首页</el-button>
      <div style="margin: 0 15px">当前模块：{{openLowerLevelName}}</div>
      <el-button type="primary" @click="getAddModule">新增模块</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="id" width="50" align="center"/>
      <el-table-column prop="code" label="code" align="center" width="150" />
      <el-table-column prop="name" label="名称" align="center" />
      <el-table-column label="封面图" align="center" width="130">
        <template #default="{row, $index}">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.coverUrl"
            :zoom-rate="1.2"
            :preview-src-list="srcList"
            :initial-index="$index"
            fit="cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center" />
      <el-table-column prop="sort" label="排序（越小越前）" align="center" width="150" />
      <el-table-column fixed="right" label="操作" width="300" align="center">
        <template #default="{row}">
          <el-button @click="getModifyModule(row)">编辑</el-button>
          <el-button type="success" @click="openModuleLowerLevel(row)">子模块</el-button>
          <el-button type="danger" @click="getDeleteModule(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <change-module
      ref="changeModuleRef"
      :dialog-type="dialogType"
      :dialog-data="dialogData"
      :refresh-key="refreshKey"
      :dialog-id="dialogId"
      @up-date-table="getUpdate"
    />
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>
<script setup>
import { ref } from 'vue'
import { moduleManagement } from '@/api/modules'
import { ElMessage, ElMessageBox } from 'element-plus'
// eslint-disable-next-line no-unused-vars
import ChangeModule from '@/views/home/components/changeModule'

const tableData = ref([])
const srcList = ref([])
const refreshKey = ref(0)
const openLowerLevelId = ref(0)
const openLowerLevelName = ref('')
const getData = () => {
  moduleManagement.getModuleList({
    parentId: openLowerLevelId.value
  }).then(res => {
    console.log(res.data)
    srcList.value = []
    const imgUrls = []
    tableData.value = res.data
    res.data.map(el => {
      imgUrls.push(el.coverUrl)
    })
    srcList.value = imgUrls
  })
}
getData()

const changeModuleRef = ref(null)
const dialogType = ref('add')
const dialogData = ref({})
const dialogId = ref(0)
// 更新
const getUpdate = (e) => {
  console.log(e)
  getData()
}

// 新增
const getAddModule = () => {
  dialogType.value = 'add'
  dialogData.value = {}
  dialogId.value = openLowerLevelId.value
  refreshKey.value++
  changeModuleRef.value.editTagDialogType()
}

// 编辑
const getModifyModule = (row) => {
  dialogType.value = 'emit'
  dialogData.value = row
  dialogId.value = row.parentId
  refreshKey.value++
  changeModuleRef.value.editTagDialogType()
}

// 删除
const getDeleteModule = ({id}) => {
  ElMessageBox.confirm('确认删除？', '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error',
    }
  ).then(() => {
    moduleManagement.deleteModule({id}).then(() => {
      ElMessage({
        message: '删除成功！',
        type: 'error',
      })
      getData()
    })
  }).catch(() => {

  })
}

// 展开下级
const openModuleLowerLevel = ({id, name}) => {
  openLowerLevelId.value = id
  openLowerLevelName.value = name
  getData()
}

// 返回首页
const getAllModule = () => {
  openLowerLevelId.value = 0
  openLowerLevelName.value = ''
  getData()
}

</script>

<style>
.home-page {
  padding: 15px;
}

.home-page-top {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.el-table .el-table__cell {
  position: static !important;
}
</style>
