<template>
  <home-page/>
</template>

<script>
export default {
  name: 'App'
}
</script>
<script setup>

import HomePage from '@/views/home/home'
</script>

<style>
</style>
