<template>
  <el-upload
    ref="upFile"
    :multiple="props.multiple"
    :limit="props.limit"
    :show-file-list="false"
    :on-exceed="handleExceed"
    :http-request="uploadAvatar"
    :auto-upload="true"
    action="#"
    accept="image/*"
  >
    <div v-if="avatarUrl" class="flex-box">
      <el-image
        style="height: 100px"
        :src="avatarUrl"
        fit="scale-down"
      />
      <el-button style="margin-left: 15px" :icon="Plus">重新上传</el-button>
      <el-button :icon="Delete" type="danger" @click.stop="deleteImg">删除</el-button>
    </div>
    <template v-else>
      <div :class="['default-img',props.shape==='square' && 'square']" :style="{width:props.avatarSize + 'px',height:props.avatarSize + 'px'}">
        <el-icon :size="26" color="#9A9A9A">
          <Plus />
        </el-icon>
        <span class="default-text">{{ props.defaultText }}</span>
      </div>
    </template>
    <template #tip>
      <div class="tips" v-text="props.tips" />
    </template>
  </el-upload>
</template>

<script>
// 上传头像控件
export default {
  name: 'UploadImg'
}
</script>

<script setup>
import { Plus, Delete } from '@element-plus/icons-vue'
import { upLoad } from '@/api/common'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  multiple: {
    type: Boolean,
    required: false,
    default: () => false
  },
  shape: {
    type: String,
    required: false,
    default: () => 'circle'
  },
  defaultText: {
    type: String,
    required: false,
    default: '上传照片'
  },
  limit: {
    type: Number,
    required: false,
    default: () => 1
  },
  avatarSize: {
    type: Number,
    required: false,
    default: () => 100
  },
  tips: {
    type: String,
    required: false,
    default: () => ''
  },
  imgUrl: {
    type: String,
    required: true
  }
})

watch(() => props.imgUrl, (val) => {
  avatarUrl.value = val
})

const emits = defineEmits(['changeAvatar'])
const upFile = ref(null)
const avatarUrl = ref('')

onMounted(() => {
  avatarUrl.value = props.imgUrl
})

// 更新替换
const handleExceed = (files) => {
  upFile.value.clearFiles()
  avatarUrl.value = ''
  uploadAvatar({ file: files[0] })
}

// 上传
const uploadAvatar = async({ file }) => {
  const form = new FormData()
  form.append('file', file)
  let apiName = upLoad.imagesUpLoad

  const { data } = await apiName(form)
  avatarUrl.value = data
  emits('changeAvatar', data)
}

// 删除
const deleteImg = () => {
  avatarUrl.value = ''
  emits('changeAvatar', '')
}

</script>

<style>
.default-img {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 1px solid #C8C8C8;
}

.default-text {
  font-size: 10px;
  color: #9A9A9A;
}

.tips {
  color: #9A9A9A;
  font-size: 12px;
}

.flex-box {
  display: flex;
  align-items: center;
}

</style>
